import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Logo from '../../../images/hope-logo.svg';

import './header.css';

const Header = () => (
  <Navbar expand="lg">
    <Container>
      <Navbar.Brand>
        <Link to="/">
          <div className="brandName">
            <Logo style={{ height: 95, display: 'inline', marginRight: 15 }} />
            HOPE
            <br />
            Initiative
          </div>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          <div className="utility small">
            <Nav.Link as="span">
              <Link to="/methodology">Methodology</Link>
            </Nav.Link>
            <Nav.Link as="span">
              <Link to="/contact">Contact</Link>
            </Nav.Link>
          </div>
          <div className="utility large">
            <Nav.Link as="span">
              <Link to="/about">About</Link>
            </Nav.Link>
            <NavDropdown title="HOPE Data">
              <NavDropdown.Item as="span">
                <Link to="/domain">By Domain</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="span">
                <Link to="/indicator">By Indicator</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="span">
                <Link to="/demographic">By Race &amp; Ethnicity</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="span">
                <Link to="/state">By State</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as="span">
              <Link to="/resources">Resources</Link>
            </Nav.Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default Header;
