import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './footer.css';

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        nche: file(relativePath: { eq: "nche.png" }) {
          childImageSharp {
            fixed(height: 40) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        rwjf: file(relativePath: { eq: "rwjf.png" }) {
          childImageSharp {
            fluid(maxHeight: 140) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        thi: file(relativePath: { eq: "thi.png" }) {
          childImageSharp {
            fixed(height: 40) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        vcu: file(relativePath: { eq: "vcu.png" }) {
          childImageSharp {
            fixed(height: 40) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Container fluid className="footer">
        <Container>
          <Row style={{ alignItems: 'center' }}>
            <Col lg={6}>
              <h2>Keep Up with Our Work</h2>
            </Col>
            <Col className="sc-bdVaJa iIDDUy" lg={6}>
              <form
                className="js-cm-form"
                id="subForm"
                action="https://www.createsend.com/t/subscribeerror?description="
                method="post"
                data-id="30FEA77E7D0A9B8D7616376B90063231794107A5422E16563DE2F52EBB19AEF9B862DB8ED8304A79BFBE200F8BF60EC1C710D88341D8028E4F8D538144DF5B83"
              >
                <Row noGutters>
                  <Col md={8}>
                    <input
                      autoComplete="Email"
                      aria-label="Email"
                      id="fieldEmail"
                      maxLength="200"
                      name="cm-qjdjiy-qjdjiy"
                      required
                      type="email"
                      className="js-cm-email-input qa-input-email sc-iwsKbI iMsgpL"
                    />
                  </Col>
                  <Col md={4}>
                    <button
                      className="js-cm-submit-button sc-jKJlTe jHkwuK"
                      variant="outline-dark"
                      type="submit"
                      style={{ marginLeft: 5 }}
                    >
                      Subscribe
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
          <Row className="logos">
            <Col lg={2} md={6}>
              <a href="https://www.nationalcollaborative.org/" target="_blank" rel="noreferrer">
                <Img fixed={data.nche.childImageSharp.fixed} />
              </a>
            </Col>
            <Col lg={2} md={6}>
              <a href="https://www.texashealthinstitute.org/" target="_blank" rel="noreferrer">
                <Img fixed={data.thi.childImageSharp.fixed} />
              </a>
            </Col>
            <Col lg={2} md={6}>
              <a href="https://societyhealth.vcu.edu/" target="_blank" rel="noreferrer">
                <Img fixed={data.vcu.childImageSharp.fixed} />
              </a>
            </Col>
            <Col lg={4} />
            <Col lg={2} md={6}>
              <a href="https://www.rwjf.org/" target="_blank" rel="noreferrer">
                <Img fluid={data.rwjf.childImageSharp.fluid} />
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
    )}
  />
);
export default Footer;
